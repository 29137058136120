<script>
import {ChevronRightIcon} from "vue-feather-icons"
import axios from 'axios'
import Footer from "@/components/footer"

let crypto = require('crypto');

export default {
  components: {
    ChevronRightIcon,
    Footer,
  },
  data() {
    return {
      restful: {
        scope: {
          id: '',
          scope: '',
          hashes: [],
        },
      },
      state: {
        phoneNumber: null,
        isPhoneNumberFound: null,
        isPhoneNumberValid: null
      },
    }
  },
  methods: {
    cleanPhoneNumber(string) {
      return string.replace(/[\s/]/g, '').substring(1)
    },
    hashPhoneNumber(string) {
      return crypto.createHash('sha1').update(string).digest('hex')
    },
    isPhoneNumberPresent(hash) {
      return this.restful.scope.hashes.includes(hash)
    },
    validatePhoneNumber(data) {
      this.state.isPhoneNumberValid = data.valid
      this.state.isPhoneNumberFound = null
    },
    checkPhoneNumber: function () {
      if (this.state.isPhoneNumberValid !== true) {
        return
      }

      this.state.isPhoneNumberFound = null
      let phoneNumber = this.cleanPhoneNumber(this.state.phoneNumber)
      let hash = this.hashPhoneNumber(phoneNumber)

      axios.get(`https://api.haveibeenzuckered.com/api/v1/scope/${hash.substring(0, 5)}`).then(
          response => {
            this.restful.scope = response.data.payload
            this.state.isPhoneNumberFound = this.isPhoneNumberPresent(hash)
          }
      ).catch(error => {
        if (error.response) {
          console.log(error.response.data)
          console.log(error.response.status);
        }
        alert('Shoot, an error occurred please try and refresh the page.')
      })
    },
  }
}
</script>

<template>
  <div>
    <div v-scroll-spy>
      <section class="hero-2 position-relative overflow-hidden"
               :style="{ 'background-image': 'url(' + require('@/assets/images/hero-2-bg.png') + ')'}" id="home">
        <div class="container">
          <div class="row align-items-center">
            <div class="col-lg-6">
              <h1 class="font-weight-semibold mb-4 hero-2-title">
                Facebook Data Breach Checker
              </h1>
              <p class="mb-3 text-muted">
                A large dataset containing 533 million Facebook accounts was made available for download. The data was
                obtained by exploiting a vulnerability that was, according to Facebook, corrected in August 2019.
              </p>
              <h2 class="mb-3 font-size-18">
                Check if your telephone number is present within the Facebook data breach.
              </h2>
              <p class="mb-3 text-muted">
                We have finalised ingesting the dataset, and we currently have processed <strong>502 769 112</strong>
                records from <strong>106</strong> different countries. This tool does not store
                user-provided input and uses k-anonymity to ensure privacy.
              </p>
              <div class="custom-search">
                <div class="input-group mb-3">
                  <vue-tel-input v-model="state.phoneNumber" @validate="validatePhoneNumber"></vue-tel-input>
                  <div class="input-group-append">
                    <button class="btn btn-primary" type="button" v-on:click="checkPhoneNumber">
                      <chevron-right-icon class=""></chevron-right-icon>
                    </button>
                  </div>
                </div>
                <p class="text-danger" v-if="this.state.isPhoneNumberValid === false">
                  Please enter a valid phone number
                </p>
              </div>
              <p class="mb-3 text-danger" v-if="this.state.isPhoneNumberFound === true">
                <strong>Oh, shoot!</strong> The phone number you entered was found in the data breach. Your name, phone
                number, profile picture and gender were included in this leak, and possibly also your place of birth,
                email address, job, relationship status and birthday.
              </p>
              <p class="mb-3 text-success" v-if="this.state.isPhoneNumberFound === false">
                Lovely! The phone number you entered was <strong>not</strong> found in the data breach.
              </p>
            </div>
            <div class="col-lg-6 col-sm-10 mx-auto ms-lg-auto me-lg-0">
              <div class="mt-lg-0 mt-4">
                <img src="@/assets/images/hero-2-img.png" alt="" class="img-xl-responsive"/>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Footer/>
    </div>
  </div>
</template>

<style>
.align-items-center {
  align-items: stretch !important
}
</style>